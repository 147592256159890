import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchGenerationImagesPages } from "@remotes/fetchGenerationImagesPages";

export function useGenerationImagesPages() {
  return useInfiniteQuery({
    queryKey: ["useGenerationImagesPages"],
    queryFn: ({ pageParam = "" }) => fetchGenerationImagesPages(pageParam),
    getNextPageParam: (lastPage) => {
      return lastPage.next
        ? new URL(lastPage.next).searchParams.get("cursor")
        : undefined;
    },
    initialPageParam: "",
  });
}
