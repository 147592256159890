import { css, cx } from "@emotion/css";
import LoadingIcon from "@images/fade-stagger-circles.svg";

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Loader = ({ width = 80, height = 80, className }: Props) => {
  const defaultStyles = css`
    width: ${width}px;
    height: ${height}px;
  `;
  return (
    <img
      src={LoadingIcon}
      alt="loading"
      className={cx(defaultStyles, className)}
    />
  );
};
