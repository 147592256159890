import { css } from "@emotion/css";
import { Spacing } from "./Spacing";

export const ERROR_MESSAGE_HEIGHT = 24;

export const ErrorMessage = ({ text }: { text?: string | null }) =>
  text ? (
    <p className={errorStyle}>{text}</p>
  ) : (
    <Spacing size={ERROR_MESSAGE_HEIGHT} />
  );

const errorStyle = css`
  color: #f04251;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  max-width: 550px;
`;
