import { useCallback, MouseEvent } from "react";
import { SetterOrUpdater } from "recoil";
import { useDropzone, FileWithPath } from "react-dropzone";
import DropIcon from "@images/drop-icon.svg";
import CloseIcon from "@images/x-close.svg";
import { css } from "@emotion/css";
import { Flex } from "@components/Flex";
import { Spacing } from "@components/Spacing";

interface Props {
  images: File[];
  setImages: SetterOrUpdater<File[]>;
}

export const DragAndDropUpload = ({ images, setImages }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setImages((images) => [...images, ...acceptedFiles]);
    },
    [setImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    noClick: false,
    noKeyboard: false,
    multiple: true,
  });

  const handleDeleteImage = (imageToDelete: File) => {
    setImages((images) =>
      images.filter(
        (image) =>
          !(
            image.name === imageToDelete.name &&
            image.size === imageToDelete.size
          )
      )
    );
  };

  const handleClearAll = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setImages([]);
  };

  return (
    <div {...getRootProps()} className={wrapperStyle}>
      <input {...getInputProps()} />
      {images.length === 0 ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          className={initialWrapperStyle}
        >
          <img src={DropIcon} alt="drop-icon" className={dropIconStyle} />
          <Spacing size={50} />
          <p className={textStyle}>
            Drag and drop training images here
            <br />
            or <u>upload</u> from a folder
          </p>
        </Flex>
      ) : (
        <div>
          <Flex justify="space-between" className={previewHeaderStyle}>
            <p className={imageCountStyle}>{images.length} images uploaded</p>
            <button className={clearAllStyle} onClick={handleClearAll}>
              Clear all
            </button>
          </Flex>
          <div className={previewWrapperStyle}>
            {[...images].reverse().map((imageFile, index) => (
              <div
                key={`${imageFile.name}-${index}`}
                className={previewImageWrapperStyle}
              >
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt={`preview-${imageFile.name}`}
                  className={previewImageStyle}
                />
                <button
                  className={deleteButtonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(imageFile);
                  }}
                >
                  <img src={CloseIcon} alt="close icon" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const wrapperStyle = css`
  width: 100%;
  flex: 1;
`;

const initialWrapperStyle = css`
  border-radius: 32px;
  border: 2px solid rgba(170, 170, 170, 0.2);
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const dropIconStyle = css`
  width: 88px;
  height: 88px;
`;

const textStyle = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
`;

const previewHeaderStyle = css`
  padding: 0 16px 16px;
`;

const previewWrapperStyle = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
  max-height: 300px;
  overflow-y: scroll;
`;

const previewImageWrapperStyle = css`
  position: relative;

  &:hover button {
    opacity: 1;
  }
`;

const previewImageStyle = css`
  display: block;
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  border: 1px solid rgba(170, 170, 170, 1);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

const deleteButtonStyle = css`
  position: absolute;
  top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  > img {
    width: 18px;
    height: 18px;
  }
`;

const imageCountStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
`;

const clearAllStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
`;
