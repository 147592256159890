import { useRecoilState } from "recoil";
import { css } from "@emotion/css";
import { RoundedButton } from "@design-system/RoundedButton";
import { StyleThumbnail } from "./StyleThumbnail";
import { Spacing } from "@components/Spacing";
import { Flex } from "@components/Flex";
import { StyleTextField } from "./StyleTextField";
import { nameState, thumbnailState } from "@recoil/newStyleState";

interface Props {
  onNext: (step?: number) => void;
}

export const StyleInfoContent = ({ onNext }: Props) => {
  const [thumbnail, setThumbnail] = useRecoilState(thumbnailState);
  const [name, setName] = useRecoilState(nameState);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      className={css`
        height: 100%;
      `}
    >
      <StyleThumbnail thumbnail={thumbnail} setThumbnail={setThumbnail} />
      <Spacing size={20} />
      <StyleTextField value={name} setValue={setName} />
      <Spacing size={100} />
      <RoundedButton
        className={css`
          width: 367px;
          height: 80px;
          font-size: 24px;
          border-radius: 100px;
          position: absolute;
          bottom: 28px;
        `}
        onClick={() => onNext()}
        disabled={name === ""}
      >
        Next
      </RoundedButton>
    </Flex>
  );
};
