import { MouseEvent } from "react";
import { css } from "@emotion/css";
import { GenerationImage } from "@remotes/fetchGenerationImagesPages";
import CheckWhite from "@images/check-white.svg";
import DownloadIcon from "@images/download.svg";
import LazyImage from "@components/LazyImage";

type Props = GenerationImage & {
  selectMode: boolean;
  selected: boolean;
  toggleSelect: (id: string) => void;
};

export const ImageCard = ({
  id,
  image,
  selectMode,
  selected,
  toggleSelect,
}: Props) => {
  const handleCardClick = () => {
    if (!selectMode) {
      return;
    }
    toggleSelect(id);
  };

  const handleImageDownload = async (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();

    try {
      const imageUrl = image.full;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = imageUrl.split("/").pop() || `image-${id}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  return (
    <div
      className={wrapperStyle(selectMode, selected)}
      onClick={handleCardClick}
    >
      <LazyImage src={image.medium} alt="" className={imageStyle} />
      <div className={checkBoxStyle(selectMode, selected)}>
        <img src={CheckWhite} alt="check icon" />
      </div>
      {selectMode && <div className={borderStyle(selected)} />}
      {selectMode && (
        <div className={`${overlayStyle} overlay`}>
          <img
            src={DownloadIcon}
            alt="download icon"
            className={downloadIconStyle}
            onClick={handleImageDownload}
          />
        </div>
      )}
    </div>
  );
};

const wrapperStyle = (selectMode: boolean, selected: boolean) => css`
  width: 225px;
  height: 225px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: ${selectMode ? "pointer" : "default"};

  &:hover .overlay {
    opacity: 1;
  }
`;

const borderStyle = (selected: boolean) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: ${selected ? "inset 0 0 0 4px #0F7DFF" : "none"};
  transition: box-shadow 0.3s ease;
`;

const checkBoxStyle = (selectMode: boolean, selected: boolean) => css`
  opacity: ${selectMode ? 1 : 0};
  position: absolute;
  top: 12px;
  left: 12px;
  width: 24px;
  height: 24px;
  background-color: ${selected ? "#0F7DFF" : "#ffffff"};
  border-radius: 6px;
  box-shadow: ${selected ? "none" : "inset 0 0 0 2px #000000"};
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease,
    box-shadow 0.3s ease;
`;

const imageStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 16px;

  /* Checkered background */
  background: linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    ),
    linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    );
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  background-color: white;
`;

const overlayStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s ease;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const downloadIconStyle = css`
  padding: 12px;
`;
