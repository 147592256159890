import { useState } from "react";
import { useRecoilState } from "recoil";
import { css } from "@emotion/css";
import { RoundedButton } from "@design-system/RoundedButton";
import { Spacing } from "@components/Spacing";
import { Flex } from "@components/Flex";
import { requestFinetuningImages } from "@remotes/requestFinetuningImages";
import { StyleThumbnail } from "./StyleThumbnail";
import { StyleTextField } from "./StyleTextField";
import { nameState, thumbnailState, imagesState } from "@recoil/newStyleState";
import { DragAndDropUpload } from "./DragAndDropUpload";
import { requestFinetuningConcept } from "@remotes/requestFinetuningConcept";
import { requestFinetuningModel } from "@remotes/requestFinetuningModel";
import { requestFinetuningModelTraining } from "@remotes/requestFinetuningModelTraining";
import { CreateStyleNoticeModal } from "./CreateStyleNoticeModal";

interface Props {
  onNext: (step?: number) => void;
}

export const ImagesUploadContent = ({ onNext: _ }: Props) => {
  const [thumbnail, setThumbnail] = useRecoilState(thumbnailState);
  const [name, setName] = useRecoilState(nameState);
  const [images, setImages] = useRecoilState(imagesState);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);

  const isImageUploaded = images.length > 0;

  const requestCreate = async () => {
    const { id: modelId } = await requestFinetuningModel(name);
    const { id: conceptId } = await requestFinetuningConcept(name, modelId);

    await Promise.all(
      images.map((image) => requestFinetuningImages(image, conceptId))
    );

    // TODO (@abel.ko): handle error case - "Model concept abel test3 does not have enough training images."
    await requestFinetuningModelTraining(modelId);

    return modelId;
  };

  return (
    <>
      <Flex
        direction="column"
        align="center"
        justify="center"
        className={css`
          height: 100%;
          padding: 20px 0 12px;
          box-sizing: border-box;
        `}
      >
        <StyleThumbnail thumbnail={thumbnail} setThumbnail={setThumbnail} />
        <Spacing size={20} />
        <StyleTextField value={name} setValue={setName} />
        <Spacing size={isImageUploaded ? 40 : 70} />
        <DragAndDropUpload images={images} setImages={setImages} />

        {isImageUploaded && (
          <RoundedButton
            className={css`
              width: 367px;
              height: 80px;
              font-size: 24px;
              border-radius: 100px;
              position: absolute;
              bottom: 28px;
            `}
            onClick={() => setNoticeModalOpen(true)}
            disabled={name === "" || !isImageUploaded}
          >
            Create Style
          </RoundedButton>
        )}
      </Flex>
      <CreateStyleNoticeModal
        open={noticeModalOpen}
        onClose={() => setNoticeModalOpen(false)}
        styleTitle={name}
        requestCreate={requestCreate}
      />
    </>
  );
};
