import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@contexts/AuthContext";
import { css } from "@emotion/css";
import { HEADER_HEIGHT } from "@components/Header";
import { Flex } from "@components/Flex";
import { Loader } from "./Loader";

const PrivateRoute: React.FC = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        className={loadingStyle}
      >
        <Loader />
      </Flex>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const loadingStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
`;

export default PrivateRoute;
