import { WAND_PRIMARY_COLOR } from "@constants/colors";
import { css } from "@emotion/css";
import LoadingIcon from "@images/fade-stagger-circles.svg";
import { ReactNode, MouseEventHandler } from "react";

interface Props {
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({
  children,
  disabled = false,
  loading = false,
  onClick,
}: Props) => {
  const _disabled = disabled || loading;

  return (
    <button
      onClick={onClick}
      disabled={_disabled}
      className={css`
        background-color: ${WAND_PRIMARY_COLOR};
        opacity: ${_disabled ? 0.45 : 1};
        height: 62px;
        border-radius: 12px;
        color: #ffffff;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        text-align: center;
        width: 460px;
        cursor: ${_disabled ? "not-allowed" : "pointer"};
        transition: background-color 0.3s ease-out, opacity 0.3s ease-out;

        &:hover {
          background-color: ${_disabled ? WAND_PRIMARY_COLOR : "#449bff"};
          opacity: ${_disabled ? 0.45 : 1};
        }
      `}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};

const Loader = () => {
  return (
    <img
      src={LoadingIcon}
      alt="loading"
      className={css`
        width: 40px;
        height: 40px;
      `}
    />
  );
};
