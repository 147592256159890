import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { css } from "@emotion/css";
import { Spacing } from "@components/Spacing";
import { Flex } from "@components/Flex";
import { useFinetuningModelProgress } from "@hooks/query/useFinetuningModelProgress";
import { useFinetuningModel } from "@hooks/query/useFinetuningModel";
import { ProgressBar } from "./ProgressBar";
import { useBackgroundColor } from "../hooks/useBackgroundColor";
import { useStyles } from "@hooks/query/useStyles";

interface Props {
  onNext: (step?: number) => void;
}

export const ProgressContent = ({ onNext }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modelId = searchParams.get("modelId");

  const { data: finetuningModelData } = useFinetuningModel(modelId);
  const { data: progressData } = useFinetuningModelProgress(modelId);

  useBackgroundColor(progressData?.status);

  const handleViewStyleClick = () => {
    queryClient.invalidateQueries({ queryKey: [useStyles.key] });
    navigate("/style-library");
  };

  return (
    <div className={wrapperStyle}>
      <div>
        <Spacing size={20} />
        {finetuningModelData && (
          <Flex direction="column" justify="center" align="center">
            <img
              src={finetuningModelData.thumbnail}
              alt="thumbnail"
              className={thumbnailStyle}
            />
            <Spacing size={40} />
            <p className={nameStyle}>{finetuningModelData.name}</p>
            <Spacing size={24} />
            <p className={imageCountStyle}>
              {finetuningModelData.concepts[0].images.length} images
            </p>
            <Spacing size={120} />
          </Flex>
        )}

        {progressData?.status === "training" && (
          <p className={statusTextStyle}>
            Training in progress...
            <br />
            (you can close this window)
          </p>
        )}

        {progressData?.status === "trained" && (
          <p className={statusTextStyle}>
            Training complete!
            <br />
            <button onClick={handleViewStyleClick} className={viewButtonStyle}>
              view style
            </button>
          </p>
        )}
      </div>

      <ProgressBar progress={progressData?.percentage_complete ?? 0} />
    </div>
  );
};

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 12px;
  box-sizing: border-box;
`;

const statusTextStyle = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
`;

const thumbnailStyle = css`
  width: 100px;
  height: 100px;
  border-radius: 200px;
  border: 1px solid #ffffff;
  object-fit: cover;
`;

const nameStyle = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;

const imageCountStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

const viewButtonStyle = css`
  text-decoration: underline;
`;
