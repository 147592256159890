import { atom } from "recoil";

export const nameState = atom({
  key: "@recoil/newStyleState/name",
  default: "",
});

export const thumbnailState = atom<string | null>({
  key: "@recoil/newStyleState/thumbnail",
  default: null,
});

export const imagesState = atom<File[]>({
  key: "@recoil/newStyleState/images",
  default: [],
});

export const DEFAULT_BACKGROUND_COLOR = "#343434";
export const COMPLETE_BACKGROUND_COLOR = "#0F7DFF";

type BackgroundColorType =
  | typeof DEFAULT_BACKGROUND_COLOR
  | typeof COMPLETE_BACKGROUND_COLOR;

export const modalBackgroundColorState = atom<BackgroundColorType>({
  key: "@recoil/newStyleState/modalBackgroundColor",
  default: DEFAULT_BACKGROUND_COLOR,
});
