import { http } from ".";

export interface FinetuningConceptImage {
  id: number;
  concept: number;
}

export interface FinetuningConcept {
  id: number;
  images: FinetuningConceptImage[];
  uuid: string;
  name: string;
  token?: string;
  created_at: Date;
  updated_at: Date;
  model: string;
}

export async function requestFinetuningConcept(name: string, model: string) {
  return http.post<FinetuningConcept>("/finetuning-concepts/", { name, model });
}
