import { getTokens, removeTokens, setTokens } from "@utils/authStorage";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { refreshAccessToken } from "./refreshAccessToken";

export const WAND_API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://wand-api-prod-iprqspit3q-ue.a.run.app"
    : "https://wand-api-dev-iprqspit3q-ue.a.run.app";

// export const WAND_API_BASE_URL =
//   "https://wand-api-prod-iprqspit3q-ue.a.run.app";

interface WandAxiosInstance extends AxiosInstance {
  <T = any>(config: any): Promise<T>;
  request<T = any>(config: any): Promise<T>;
  get<T = any>(url: string, config?: any): Promise<T>;
  delete<T = any>(url: string, config?: any): Promise<T>;
  post<T = any>(url: string, data?: any, config?: any): Promise<T>;
  put<T = any>(url: string, data?: any, config?: any): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: any): Promise<T>;
}

type WandAxiosRequestConfig = AxiosRequestConfig & {
  _retry?: boolean;
};

export const http = axios.create({
  baseURL: WAND_API_BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
}) as WandAxiosInstance;

export const requestInterceptor = http.interceptors.request.use(
  (config) => {
    const { authToken } = getTokens();
    if (authToken) {
      config.headers["Authorization"] = `Token ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const responseInterceptor = http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as WandAxiosRequestConfig;

    if (originalRequest == null) {
      return Promise.reject(error);
    }

    const isUnauthorized = error.response?.status === 401;
    const hasNotRetried = !originalRequest._retry;

    if (isUnauthorized && hasNotRetried) {
      return handleUnauthorizedError(originalRequest);
    }

    return Promise.reject(error);
  }
);

const handleUnauthorizedError = async (
  originalRequest: WandAxiosRequestConfig
) => {
  originalRequest._retry = true;

  const { refreshToken } = getTokens();
  if (refreshToken == null) {
    return Promise.reject("No refresh token available");
  }

  const newAccessToken = await refreshAccessToken(refreshToken);
  if (newAccessToken == null) {
    removeTokens();
    window.location.href = "/login";
    return Promise.reject("Token refresh failed");
  }

  setTokens(newAccessToken, refreshToken);
  originalRequest.headers = {
    ...originalRequest.headers,
    Authorization: `Token ${newAccessToken}`,
  };

  return http(originalRequest);
};
