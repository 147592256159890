import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { RoundedButton } from "@design-system/RoundedButton";
import { PlanCard } from "./components/PlanCard";
import { Plan } from "./constants";

const SignUp = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState<Plan>("BASIC");

  const handleCTAClick = () => {
    navigate("/sign-up/email-address");
  };

  const handleLogInBtnClick = () => {
    navigate("/login");
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>Sign up for Wand</SignInUpTitle>
      <Spacing size={56} />
      <PlanCard
        plan="BASIC"
        price="1-week free trial"
        rowTexts={[
          "1 week free than $9.99/month",
          "Generate 500 images per month",
          "Create 10 styles per month",
        ]}
        selected={plan === "BASIC"}
        onClick={() => setPlan("BASIC")}
      />
      <Spacing size={8} />
      <PlanCard
        plan="PRO"
        price="$39.99/mo"
        rowTexts={[
          "Generate 5,000 images per month",
          "Create 30 styles per month",
        ]}
        selected={plan === "PRO"}
        onClick={() => setPlan("PRO")}
      />
      <Spacing size={32} />
      <RoundedButton
        onClick={handleCTAClick}
        aria-label="Subscribe to the selected plan"
      >
        Subscribe
      </RoundedButton>
      <Spacing size={16} />
      <p className={priceInstructionStyle}>
        Free for 1 week, than $9.99 a month.
        <br />
        You can cancel anytime.
      </p>
      <Spacing size={48} />
      <Border />
      <Spacing size={32} />
      <p
        className={css`
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: center;
          color: #aaaaaa;
          margin-bottom: 6px;
        `}
      >
        Already have an account?
      </p>
      <TextButton onClick={handleLogInBtnClick}>Log in</TextButton>
      <Spacing size={280} />
    </div>
  );
};

const Border = styled.div`
  width: 326px;
  height: 1px;
  background: #343434;
`;

const TextButton = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
`;

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 0 0;
`;

const priceInstructionStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #aaaaaa80;
`;

export default SignUp;
