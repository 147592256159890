import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { ErrorMessage } from "@components/ErrorMessage";
import { requestVerificationCheck } from "@remotes/requestVerificationCheck";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState("");
  const [email, setEmail] = useState<string | null>(null);
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError] = useErrorHandling();

  // TODO: Separate with custom hook
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromQuery = queryParams.get("email");
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
  }, [location.search]);

  const handleCodeChange = (value: string) => {
    setCode(value);
    clearError();
  };

  const handleConfirm = async () => {
    if (email == null) {
      return;
    }
    // TODO: 에러 발생하면 그냥 터져버린다. 이런거 우아하게 에러 처리하려면 어떻게 해야할지 더 고민해보기
    startLoading();
    try {
      await requestVerificationCheck(code, email);
      navigate("/sign-up/create-account");
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>Verify your email</SignInUpTitle>
      <Spacing size={100} />
      <TextField
        placeholder="Verification code"
        value={code}
        onChange={(e) => handleCodeChange(e.target.value)}
      />
      <ErrorMessage text={error} />
      <Spacing size={22} />
      <Button
        disabled={code.length < 1 || error != null}
        loading={loading}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
      <Spacing size={600} />
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default VerifyEmail;
