import { css } from "@emotion/css";
import { ReactNode } from "react";

export const SignInUpTitle = ({ children }: { children: ReactNode }) => {
  return <h1 className={titleStyle}>{children}</h1>;
};

const titleStyle = css`
  font-weight: 750;
  font-size: 68px;
  text-align: center;
  color: #ffffff;
`;
