import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { requestLogin } from "@remotes/requestLogin";
import { getTokens, removeTokens, setTokens } from "@utils/authStorage";
import { useLoading } from "@hooks/useLoading";
import { refreshAccessToken } from "@remotes/refreshAccessToken";
import { useLocation } from "react-router-dom";

type AuthContextType = {
  isAuthenticated: boolean;
  login: (params: LoginParams) => void;
  logout: () => void;
  loading: boolean;
};

type LoginParams = {
  email?: string;
  username?: string;
  password: string;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const excludedPaths = [
  "/sign-up",
  "/sign-up/email-address",
  "/sign-up/verify-email",
  "/sign-up/create-account",
  "/login",
];

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, , stopLoading] = useLoading(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (excludedPaths.includes(location.pathname)) {
        return stopLoading();
      }

      const { refreshToken } = getTokens();
      if (refreshToken == null) {
        return stopLoading();
      }

      const accessToken = await refreshAccessToken(refreshToken);
      if (accessToken == null) {
        return stopLoading();
      }

      setTokens(accessToken, refreshToken);
      setIsAuthenticated(true);
      stopLoading();
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopLoading]);

  const login = async ({ email, username, password }: LoginParams) => {
    if (!email && !username) {
      throw new Error("Email or username is required.");
    }

    const payload = email ? { email, password } : { username, password };

    const { token, refresh } = await requestLogin(payload);

    setTokens(token, refresh);
    setIsAuthenticated(true);
  };

  const logout = () => {
    const result = window.confirm("Are you sure you want to log out?");
    if (!result) {
      return;
    }

    removeTokens();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
