import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { useLoading } from "@hooks/useLoading";
import { ErrorMessage } from "@components/ErrorMessage";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { isValidEmail } from "@utils/validation";
import { useAuth } from "@contexts/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError, setError] = useErrorHandling();

  const handleIdentifierChange = (value: string) => {
    setIdentifier(value);
    clearError();
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    clearError();
  };

  const handleSubmit = async () => {
    const isEmail = isValidEmail(identifier);

    if (!isEmail && identifier.trim().length < 1) {
      return setError("Please enter a valid email or username.");
    }

    if (password.trim().length < 1) {
      return setError("Please enter your password.");
    }

    startLoading();
    try {
      const payload = {
        ...(isEmail ? { email: identifier } : { username: identifier }),
        password,
      };

      await login(payload);
      navigate("/style-library");
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  const isButtonDisabled =
    identifier.length < 1 || password.length < 1 || error != null;

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>
        Log into your
        <br />
        account
      </SignInUpTitle>
      <Spacing size={70} />
      <TextField
        type="text"
        placeholder="Email or Username"
        value={identifier}
        onChange={(e) => handleIdentifierChange(e.target.value)}
      />
      <Spacing size={12} />
      <TextField
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => handlePasswordChange(e.target.value)}
      />
      <ErrorMessage text={error} />
      <Spacing size={60} />
      <Button
        disabled={isButtonDisabled}
        loading={loading}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Login;
