import { useState, useRef, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { css } from "@emotion/css";
import { Flex } from "@components/Flex";
import { useGenerationImagesPages } from "@hooks/query/useGenerationImagesPages";
import { useLoading } from "@hooks/useLoading";
import { GenerationImage } from "@remotes/fetchGenerationImagesPages";
import { ImageGroup } from "./components/ImageGroup";
import { DownloadButton } from "./components/DownloadButton";
import { Loader } from "@components/Loader";

const MyLibrary = () => {
  const [selectMode, setSelectMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
  const [downloading, startDownloading, stopDownloading] = useLoading();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGenerationImagesPages();

  const observerRef = useRef(null);

  const groupedData = groupByDate(data?.pages.flatMap((page) => page.results));

  useEffect(() => {
    if (!observerRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        threshold: 1.0,
      }
    );

    observer.observe(observerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const handleDownloadImages = async () => {
    if (selectedImages.size === 0) return;
    startDownloading();

    const zip = new JSZip();
    const folder = zip.folder("images");

    const imageUrlsMap = getImageUrlsMap(
      data?.pages.flatMap((page) => page.results)
    );

    try {
      const imagePromises = Array.from(selectedImages).map(async (imageId) => {
        const imageUrl = imageUrlsMap[imageId].image.full;
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const fileName = imageUrl.split("/").pop() || "image.jpg";
        folder?.file(fileName, blob);
      });

      await Promise.all(imagePromises);

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "images.zip");
      setSelectedImages(new Set());
    } catch (e) {
      console.error(e);
    } finally {
      stopDownloading();
    }
  };

  return (
    <>
      <Flex direction="column" align="center" className={wrapperStyle}>
        {Object.entries(groupedData).map(([date, images]) => (
          <ImageGroup
            key={date}
            date={date}
            images={images}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
        ))}
        <div ref={observerRef} style={{ height: 20 }} />
        {isFetchingNextPage && <Loader />}
        <DownloadButton
          loading={downloading || selectedImages.size === 0}
          visible={selectedImages.size > 0}
          onClick={handleDownloadImages}
        >
          Download {selectedImages.size} images
        </DownloadButton>
      </Flex>
    </>
  );
};

const groupByDate = (data?: GenerationImage[]) => {
  return (
    data?.reduce((acc: { [key: string]: GenerationImage[] }, currentItem) => {
      const date = currentItem.created_at.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(currentItem);
      return acc;
    }, {}) || {}
  );
};

const getImageUrlsMap = (data?: GenerationImage[]) => {
  return (
    data?.reduce((acc: { [key: string]: GenerationImage }, image) => {
      acc[image.id] = image;
      return acc;
    }, {}) || {}
  );
};

const wrapperStyle = css`
  box-sizing: border-box;
  padding: 64px 0px;
`;

export default MyLibrary;
