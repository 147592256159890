import { useMemo } from "react";
import { Flex } from "@components/Flex";
import { Spacing } from "@components/Spacing";
import { css } from "@emotion/css";
import { Style } from "@remotes/fetchStyles";
import ProcessingIcon from "@images/styleLabelProcessing.svg";
import FailedIcon from "@images/styleLabelFailed.svg";
import { useNavigate } from "react-router-dom";
import { useFinetuningModelProgress } from "@hooks/query/useFinetuningModelProgress";

const FALLBACK_THUMBNAIL =
  "https://storage.googleapis.com/wand-images-public/wandimages/266aa076-8fcf-4010-b32b-9bc16a048cb5-upload.png";

export const StyleCard = ({
  id,
  name,
  thumbnail,
  status,
  public: publicStyle,
}: Style) => {
  const navigate = useNavigate();

  const { data } = useFinetuningModelProgress(id, status === "training");

  const handleClick = () => {
    if (!publicStyle) {
      if (status === "training") {
        return navigate(`/style-library?modal=new-style&step=4&modelId=${id}`);
      }
      if (status === "trained") {
        return navigate(`/style-library?modal=new-style&step=5&modelId=${id}`);
      }
    }
  };

  const statusLabel = useMemo(() => {
    switch (status) {
      case "training":
        return ProcessingIcon;
      case "failed":
        return FailedIcon;
      default:
        return undefined;
    }
  }, [status]);

  return (
    <Flex
      direction="column"
      align="center"
      className={wrapperStyle}
      onClick={handleClick}
    >
      <div className={thumbnailWrapperStyle}>
        <img
          className={thumbnailStyle}
          src={thumbnail ?? FALLBACK_THUMBNAIL}
          alt="style-card"
        />
        {data && (
          <div
            className={overlayStyle}
            style={{
              background: `conic-gradient(transparent ${data.percentage_complete}%, rgba(0, 0, 0, 0.7) ${data.percentage_complete}%)`,
            }}
          />
        )}
        {statusLabel && (
          <img
            src={ProcessingIcon}
            alt="status-label"
            className={statusLabelStyle}
          />
        )}
      </div>
      <Spacing size={8} />
      <p className={nameStyle}>{name}</p>
    </Flex>
  );
};

const wrapperStyle = css`
  cursor: pointer;
`;

const thumbnailWrapperStyle = css`
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const statusLabelStyle = css`
  position: absolute;
  bottom: 4px;
  left: 4px;
`;

const thumbnailStyle = css`
  width: 88px;
  height: 88px;
  border-radius: 88px;
  object-fit: cover;
`;

const nameStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #aaaaaa;
  text-align: center;
`;

const overlayStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 88px;
  transition: background 0.3s ease;
  pointer-events: none;
`;
