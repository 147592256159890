import { css } from "@emotion/css";
import CheckBlue from "@images/check-blue.svg";
import { Spacing } from "@components/Spacing";
import { Flex } from "@components/Flex";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { Plan } from "../constants";

interface Props {
  plan: Plan;
  price: string;
  rowTexts: string[];
  selected: boolean;
  onClick: () => void;
}

export const PlanCard = ({
  plan,
  price,
  rowTexts,
  selected,
  onClick,
}: Props) => (
  <div className={wrapperStyle(selected)} onClick={() => onClick()}>
    <Flex justify="space-between" align="center">
      <Flex align="center">
        <Radio selected={selected} />
        <Spacing size={8} horizental />
        <div className={primaryTextStyle}>{capitalizeFirstLetter(plan)}</div>
      </Flex>
      <div className={primaryTextStyle}>{price}</div>
    </Flex>
    <Spacing size={20} />
    {rowTexts.map((text, i) => (
      <div key={i} className={rowStyle}>
        <div className={secondaryTextStyle}>{text}</div>
        <img src={CheckBlue} alt="check" />
      </div>
    ))}
  </div>
);

const Radio = ({ selected }: { selected: boolean }) => (
  <div
    className={css`
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 2px solid #454545;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  >
    <div
      className={css`
        background: ${selected ? "#0f7dff" : "transparent"};
        width: 16px;
        height: 16px;
        border-radius: 16px;
        transition: background-color 0.3s ease-in-out;
      `}
    />
  </div>
);

const wrapperStyle = (selected: boolean) => css`
  box-sizing: border-box;
  width: 374px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #454545;
  background-color: ${selected ? "#383838" : "transparent"};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
`;

const primaryTextStyle = css`
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
`;

const secondaryTextStyle = css`
  color: #aaaaaa;
  font-weight: 500;
`;

const rowStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #343434;
  padding: 12px 0px;
`;
