import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  modalBackgroundColorState,
  COMPLETE_BACKGROUND_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "@recoil/newStyleState";
import { TrainingStatus } from "@remotes/models";

export const useBackgroundColor = (status?: TrainingStatus) => {
  const [, setBackground] = useRecoilState(modalBackgroundColorState);

  useEffect(() => {
    if (status === "trained") {
      setBackground(COMPLETE_BACKGROUND_COLOR);
    }

    return () => {
      setBackground(DEFAULT_BACKGROUND_COLOR);
    };
  }, [status, setBackground]);
};
