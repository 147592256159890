import { useSearchParams } from "react-router-dom";
import { useFinetuningModel } from "@hooks/query/useFinetuningModel";
import { Flex } from "@components/Flex";
import { Spacing } from "@components/Spacing";
import { RoundedButton } from "@design-system/RoundedButton";
import { css } from "@emotion/css";
import { Loader } from "@components/Loader";
import { StrengthenStyleNoticeModal } from "@components/StrengthenStyleNoticeModal";
import { useState } from "react";

interface Props {
  onNext: (step?: number) => void;
}

export const ResultContent = ({ onNext }: Props) => {
  const [searchParams] = useSearchParams();
  const modelId = searchParams.get("modelId");

  const [noticeModalOpen, setNoticeModalOpen] = useState(false);

  const { data: finetuningModelData, isLoading } = useFinetuningModel(modelId);

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        className={css`
          height: 100%;
        `}
      >
        <Loader />
      </Flex>
    );
  }

  if (finetuningModelData == null) {
    return <div>There is no data</div>;
  }

  const { sample_images, concepts } = finetuningModelData;

  const handleCTAClick = () => {
    setNoticeModalOpen(true);
  };

  return (
    <>
      <div
        className={css`
          position: relative;
          overflow-y: scroll;
        `}
      >
        <Flex direction="column" justify="center" align="center">
          <img
            src={finetuningModelData.thumbnail}
            alt="thumbnail"
            className={thumbnailStyle}
          />
          <Spacing size={40} />
          <p className={nameStyle}>{finetuningModelData.name}</p>
          <Spacing size={24} />
          <p className={trainingCompleteStyle}>training complete</p>
          <Spacing size={36} />
        </Flex>

        <p className={imageCountStyle}>sample outputs</p>
        <div className={previewWrapperStyle}>
          {sample_images.slice(0, 5).map(({ image: { id, medium } }) => (
            <div key={id} className={previewImageWrapperStyle}>
              <img
                src={medium}
                alt={`preview-${id}`}
                className={previewImageStyle}
              />
            </div>
          ))}
        </div>

        <Spacing size={40} />

        <p className={imageCountStyle}>training images</p>
        <div className={previewWrapperStyle}>
          {concepts[0].images.map(({ image: { id, medium } }) => (
            <div key={id} className={previewImageWrapperStyle}>
              <img
                src={medium}
                alt={`preview-${id}`}
                className={previewImageStyle}
              />
            </div>
          ))}
        </div>
      </div>
      <RoundedButton
        className={css`
          width: 367px;
          height: 80px;
          font-size: 24px;
          border-radius: 100px;
          position: absolute;
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
        `}
        onClick={handleCTAClick}
      >
        Strengthen style
      </RoundedButton>
      <StrengthenStyleNoticeModal
        open={noticeModalOpen}
        onClose={() => setNoticeModalOpen(false)}
        styleTitle={finetuningModelData.name}
        modelId={modelId!}
      />
    </>
  );
};

const thumbnailStyle = css`
  width: 100px;
  height: 100px;
  border-radius: 200px;
  border: 1px solid #ffffff;
  object-fit: cover;
`;

const nameStyle = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;

const trainingCompleteStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

const imageCountStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0px 0px 12px 12px;
`;

const previewWrapperStyle = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
  max-height: 300px;
`;

const previewImageWrapperStyle = css`
  position: relative;

  &:hover button {
    opacity: 1;
  }
`;

const previewImageStyle = css`
  display: block;
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  border: 1px solid rgba(170, 170, 170, 1);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;
