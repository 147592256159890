import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { requestSignUpStart } from "@remotes/requestSignUpStart";
import { isValidEmail } from "@utils/validation";
import { ErrorMessage } from "@components/ErrorMessage";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";

const EmailAddress = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError, setError] = useErrorHandling();

  const handleEmailChange = (value: string) => {
    setEmail(value);
    clearError();
  };

  const handleNextClick = async () => {
    if (!isValidEmail(email)) {
      return setError("Please enter a valid email address.");
    }
    clearError();
    // TODO: Handle already exists account
    startLoading();
    try {
      await requestSignUpStart(email);
      navigate(`/sign-up/verify-email?email=${email}`);
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>Email address</SignInUpTitle>
      <Spacing size={100} />
      <TextField
        placeholder="Email"
        value={email}
        onChange={(e) => handleEmailChange(e.target.value)}
      />
      <ErrorMessage text={error} />
      <Spacing size={46} />
      <Button
        disabled={email.length < 1 || error != null}
        loading={loading}
        onClick={handleNextClick}
      >
        Next
      </Button>
      <Spacing size={600} />
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default EmailAddress;
