import { useStyles } from "@hooks/query/useStyles";
import { StyleCard } from "./components/StyleCard";
import { css } from "@emotion/css";
import { Flex } from "@components/Flex";

const StyleLibrary = () => {
  const { data } = useStyles();

  return (
    <Flex direction="column" align="center">
      <div className={cardWrapperStyle}>
        {data?.map((v) => {
          return <StyleCard key={v.id} {...v} />;
        })}
      </div>
    </Flex>
  );
};

const cardWrapperStyle = css`
  max-width: 786px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 24px 8px;
  justify-items: center;
  margin-top: 100px;
`;

export default StyleLibrary;
