import { useCurrentSubscription } from "@hooks/query/useCurrentSubscription";
import { RoundedButton } from "@design-system/RoundedButton";
import { css } from "@emotion/css";
import Modal, { Styles } from "react-modal";
import { useLoading } from "@hooks/useLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "@components/Flex";

Modal.setAppElement("#root");

interface Props {
  open: boolean;
  onClose: () => void;
  requestCreate: () => Promise<string>;
  styleTitle: string;
}

export const CreateStyleNoticeModal = ({
  open,
  onClose,
  requestCreate,
  styleTitle,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useCurrentSubscription();

  const [loading, startLoading, stopLoading] = useLoading();

  const handleClose = () => {
    onClose();
  };

  const handleStartClick = async () => {
    if (!hasCredit) {
      return;
    }

    try {
      startLoading();
      const modelId = await requestCreate();

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modelId", modelId);
      searchParams.set("step", "4");

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const hasCredit = data?.style_credits != null && data.style_credits > 0;

  return (
    <Modal
      isOpen={open}
      style={modalStyles}
      contentLabel="Example Modal"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleClose}
      closeTimeoutMS={300} // NOTE (@abel.ko): Animation styles for the overlay are defined in index.css under .ReactModal__Overlay
    >
      <div className={titleStyle}>{styleTitle}</div>

      <Border />

      <div className={descriptionStyle}>
        Wand will create a custom style based on the images you supplied.
        Creating this style will take about 15 minutes.
        <br />
        <br />
        When you create a style with Wand, your uploaded images remain private
        and secure. They are used exclusively to train your personalized model
        and are not added to a larger dataset.
      </div>

      <Border />

      <Flex
        align="center"
        justify="space-between"
        className={css`
          width: 100%;
        `}
      >
        <div className={creditLabelStyle}>Available style credits</div>
        <div className={creditValueStyle}>{data?.style_credits ?? 0}</div>
      </Flex>

      <Border />

      <RoundedButton
        onClick={handleStartClick}
        className={css`
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
        `}
        disabled={!hasCredit}
        loading={loading}
      >
        Start
      </RoundedButton>
      <RoundedButton
        onClick={handleClose}
        className={css`
          font-size: 18px;
          font-weight: 600;
          background-color: #454545;
          &:hover {
            background-color: #5a5a5a;
          }
        `}
      >
        Cancel
      </RoundedButton>
    </Modal>
  );
};

const Border = () => {
  return (
    <div
      className={css`
        width: 100%;
        height: 1px;
        margin: 26px 0;
        background-color: #616161;
      `}
    />
  );
};

const modalStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    position: "relative",
    inset: 0,
    boxSizing: "border-box",
    maxWidth: 400,
    width: "100%",
    border: "2px solid #454545",
    borderRadius: 32,
    padding: "36px",
    backgroundColor: "#343434",
    color: "#ffffff",
    transition: "background-color 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
} as Styles;

const titleStyle = css`
  font-weight: 600;
  font-size: 20px;
`;

const descriptionStyle = css`
  font-size: 18px;
  line-height: 22px;
  color: #bdbdbd;
`;

const creditLabelStyle = css`
  font-size: 18px;
  font-weight: 600;
  color: #bdbdbd;
`;

const creditValueStyle = css`
  font-size: 18px;
  font-weight: 600;
  color: white;
`;
