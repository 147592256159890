import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

type ModalKey = "modal";
type ModalValue = "new-style";
type StepKey = "step";

/**
 * Custom hook to manage modal state with steps using URL query parameters.
 * @param {string} modalKey - The key used in the query parameter to control the modal (e.g., "modal").
 * @param {string} modalValue - The value in the query parameter that signifies the modal is open (e.g., "new-style").
 * @param {string} stepKey - The key used in the query parameter to control the current step (e.g., "step").
 * @returns {object} An object containing modal open state, current step, and functions to open, close, and navigate steps.
 */
export const useModalWithUrl = (
  modalKey: ModalKey = "modal",
  modalValue: ModalValue = "new-style",
  stepKey: StepKey = "step"
) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isOpen = searchParams.get(modalKey) === modalValue;
  const currentStep = parseInt(searchParams.get(stepKey) || "1", 10) || 1;

  const openModal = () => {
    setSearchParams({ [modalKey]: modalValue, [stepKey]: "1" });
  };

  const closeModal = () => {
    searchParams.delete(modalKey);
    searchParams.delete(stepKey);
    setSearchParams(searchParams);
  };

  const nextStep = (step?: number) => {
    let stepValue = currentStep + 1;
    if (typeof step === "number") {
      stepValue = step;
    }

    setSearchParams({
      [modalKey]: modalValue,
      [stepKey]: stepValue.toString(),
    });
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setSearchParams({
        [modalKey]: modalValue,
        [stepKey]: (currentStep - 1).toString(),
      });
    }
  };

  useEffect(() => {
    if (!isOpen && location.search.includes(`${modalKey}=${modalValue}`)) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return { isOpen, currentStep, openModal, closeModal, nextStep, prevStep };
};
