import { Dispatch, SetStateAction } from "react";
import { css } from "@emotion/css";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const StyleTextField = ({ value, setValue }: Props) => {
  return (
    <input
      className={inputStyle(value.length > 0)}
      type="text"
      placeholder="Name your style"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const inputStyle = (filled: boolean) => css`
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  max-width: 326px;
  height: 64px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  background: #444444;
  border: none;
  box-shadow: inset 0 0 0 1px ${filled ? "#ffffff" : "#909090"};
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  :hover {
    box-shadow: inset 0 0 0 2px #25478c;
  }

  :focus {
    box-shadow: inset 0 0 0 2px #0f7dff;
  }
`;
