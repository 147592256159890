import { ChangeEvent, Dispatch, SetStateAction } from "react";
import UploadImageIcon from "@images/upload-image-icon.svg";
import { css } from "@emotion/css";

interface Props {
  thumbnail: string | null;
  setThumbnail: Dispatch<SetStateAction<string | null>>;
}

export const StyleThumbnail = ({ thumbnail, setThumbnail }: Props) => {
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setThumbnail(URL.createObjectURL(file));
    }
  };

  return (
    <div className={uploadWrapperStyle}>
      <input
        type="file"
        accept="image/*"
        id="imageUpload"
        className={inputStyle}
        onChange={handleImageChange}
      />
      <label htmlFor="imageUpload" className={uploadButtonStyle(!!thumbnail)}>
        {thumbnail ? (
          <img src={thumbnail} alt="Uploaded" className={imagePreviewStyle} />
        ) : (
          <img src={UploadImageIcon} alt="upload-image-icon" />
        )}
      </label>
    </div>
  );
};

const uploadWrapperStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const inputStyle = css`
  display: none;
`;

const uploadButtonStyle = (filled: boolean) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: ${filled ? "2px solid #ffffff" : "2px dashed #909090"};
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  background-color: #444444;
  position: relative;
`;

const imagePreviewStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;
