import { http } from ".";

interface User {
  uuid: string;
  username: string;
  phonenumber?: string;
  email?: string;
}
interface LoginResponse {
  refresh: string;
  user: User;
  token?: string;
}

export async function requestLogin({
  email,
  username,
  password,
}: {
  email?: string;
  username?: string;
  password: string;
}) {
  return http.post<LoginResponse>("/login/", {
    ...(email ? { email } : { username }),
    password,
  });
}
