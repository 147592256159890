import { HTMLInputTypeAttribute } from "react";
import { css } from "@emotion/css";

interface InputProps {
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<InputProps> = ({
  placeholder,
  type = "text",
  value,
  onChange,
}) => {
  return (
    <input
      className={inputStyle}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

const inputStyle = css`
  box-sizing: border-box;
  width: 100%;
  max-width: 550px;
  padding: 28px 26px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  background: #0000004d;
  border: none;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px #adadad;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  ::placeholder {
    color: #aaaaaa;
  }

  :hover {
    box-shadow: inset 0 0 0 2px #25478c;
  }

  :focus {
    box-shadow: inset 0 0 0 2px #0f7dff;
  }
`;
