import { WAND_PRIMARY_COLOR } from "@constants/colors";
import { css, cx } from "@emotion/css";
import LoadingIcon from "@images/fade-stagger-circles.svg";
import { ReactNode, MouseEventHandler } from "react";

interface Props {
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const RoundedButton = ({
  children,
  disabled = false,
  loading = false,
  onClick,
  className,
}: Props) => {
  const _disabled = disabled || loading;

  return (
    <button
      onClick={onClick}
      disabled={_disabled}
      className={cx(
        css`
          background-color: ${WAND_PRIMARY_COLOR};
          opacity: ${_disabled ? 0.45 : 1};
          height: 56px;
          border-radius: 32px;
          color: #ffffff;
          font-size: 15px;
          line-height: 15px;
          font-weight: 500;
          text-align: center;
          width: 326px;
          cursor: ${_disabled ? "not-allowed" : "pointer"};
          transition: background-color 0.3s ease-out, opacity 0.3s ease-out;

          &:hover {
            background-color: ${_disabled ? WAND_PRIMARY_COLOR : "#449bff"};
            opacity: ${_disabled ? 0.45 : 1};
          }
        `,
        className
      )}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};

const Loader = () => {
  return (
    <img
      src={LoadingIcon}
      alt="loading"
      className={css`
        width: 40px;
        height: 40px;
      `}
    />
  );
};
