import axios from "axios";
import { WAND_API_BASE_URL } from ".";

export const refreshAccessToken = async (refreshToken: string) => {
  try {
    const response = await axios.post<{ token: string }>(
      `${WAND_API_BASE_URL}/auth/`,
      {},
      { headers: { Authorization: `Token ${refreshToken}` } }
    );
    return response.data.token;
  } catch (error) {
    console.error("Token refresh failed:", error);
    return null;
  }
};
