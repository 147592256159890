import { css } from "@emotion/css";

interface Props {
  progress: number;
}

export const ProgressBar = ({ progress }: Props) => {
  return (
    <div className={containerStyle}>
      <div className={progressBarStyle(progress)}></div>
    </div>
  );
};

const containerStyle = css`
  background: #181818;
  border-radius: 100px;
  height: 40px;
  width: 100%;
`;

const progressBarStyle = (progress: number) => css`
  background: #63d0ff;
  height: 100%;
  border-radius: 100px;
  width: ${progress}%;
  transition: width 1s ease;
`;
