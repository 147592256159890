import { http } from ".";
import { FinetuningModel } from "./models";

export async function requestFinetuningModel(
  name: string,
  foundation_model = "sdxl"
) {
  return http.post<FinetuningModel>("/finetuning-models/", {
    name,
    foundation_model,
  });
}
