export type AuthToken = string | null;
export type RefreshToken = string | null;

const AUTH_TOKEN = "authToken";
const REFRESH_TOKEN = "refreshToken";

export const setTokens = (
  authToken?: AuthToken,
  refreshToken?: RefreshToken
): void => {
  if (authToken != null) {
    localStorage.setItem(AUTH_TOKEN, authToken);
  }
  if (refreshToken != null) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const getTokens = (): {
  authToken: AuthToken;
  refreshToken: RefreshToken;
} => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return {
    authToken,
    refreshToken,
  };
};

export const removeTokens = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};
