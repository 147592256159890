import { useRecoilState } from "recoil";
import { useQueryClient } from "@tanstack/react-query";
import Modal, { Styles } from "react-modal";
import CloseIcon from "@images/x-close.svg";
import { css } from "@emotion/css";
import { modalBackgroundColorState } from "@recoil/newStyleState";
import { useStyles } from "@hooks/query/useStyles";
import { IntroContent } from "./components/IntroContent";
import { StyleInfoContent } from "./components/StyleInfoContent";
import { ImagesUploadContent } from "./components/ImagesUploadContent";
import { ProgressContent } from "./components/ProgressContent";
import { ResultContent } from "./components/ResultContent";

Modal.setAppElement("#root");

interface Props {
  open: boolean;
  step: number;
  onClose: () => void;
  onNext: (step?: number) => void;
  onBack: () => void;
}

export const NewStyleModal = ({
  open,
  step,
  onClose,
  onNext,
  onBack,
}: Props) => {
  const queryClient = useQueryClient();

  const [background] = useRecoilState(modalBackgroundColorState);

  const handleClose = () => {
    let shouldClose = true;

    if (step !== 1 && step !== 4 && step !== 5) {
      shouldClose = window.confirm("Are you sure you want to close?");
    }

    if (!shouldClose) {
      return;
    }

    queryClient.invalidateQueries({ queryKey: [useStyles.key] });
    onClose();
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return <IntroContent onNext={onNext} />;
      case 2:
        return <StyleInfoContent onNext={onNext} />;
      case 3:
        return <ImagesUploadContent onNext={onNext} />;
      case 4:
        return <ProgressContent onNext={onNext} />;
      case 5:
        return <ResultContent onNext={onNext} />;
      default:
        return <IntroContent onNext={onNext} />;
    }
  };

  return (
    <Modal
      isOpen={open}
      style={modalStyles(background)}
      contentLabel="Example Modal"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleClose}
      closeTimeoutMS={300} // NOTE (@abel.ko): Animation styles for the overlay are defined in index.css under .ReactModal__Overlay
    >
      <CloseButton onClick={handleClose} />
      {renderStep(step)}
    </Modal>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <img
      src={CloseIcon}
      alt="close button"
      className={css`
        position: absolute;
        cursor: pointer;
        z-index: 9999;
      `}
      onClick={onClick}
    />
  );
};

const modalStyles = (backgroundColor = "#343434") =>
  ({
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
      backdropFilter: "blur(4px)",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      position: "relative",
      inset: 0,
      boxSizing: "border-box",
      maxWidth: 800,
      width: "100%",
      height: 760,
      border: "2px solid #454545",
      borderRadius: 64,
      padding: "40px 40px 28px 40px",
      backgroundColor,
      color: "#ffffff",
      transition: "background-color 0.3s ease-in-out",
    },
  } as Styles);
