import { css, cx } from "@emotion/css";
import { Spacing } from "@components/Spacing";
import { RoundedButton } from "@design-system/RoundedButton";
import { Flex } from "@components/Flex";
import Watercolor1 from "@images/watercolor-1.png";
import Watercolor2 from "@images/watercolor-2.png";
import Watercolor3 from "@images/watercolor-3.png";
import Desertsoldier1 from "@images/desertsoldier-1.png";
import Desertsoldier2 from "@images/desertsoldier-2.png";
import Desertsoldier3 from "@images/desertsoldier-3.png";

interface Props {
  onNext: (step?: number) => void;
}

export const IntroContent = ({ onNext }: Props) => {
  return (
    <Flex direction="column" align="center">
      <Spacing size={10} />
      <p
        className={cx(
          textStyle,
          css`
            max-width: 600px;
          `
        )}
      >
        Tip: Use high quality images that have a consistent theme. It's better
        to have a few great images than several decent images.
      </p>

      <div className={imageBoxStyle}>
        <ExampleStyle
          title="Watercolor"
          images={[Watercolor1, Watercolor2, Watercolor3]}
        />
        <Spacing size={40} />
        <ExampleStyle
          title="Desert Soldier"
          images={[Desertsoldier1, Desertsoldier2, Desertsoldier3]}
        />
        <a
          href="https://youtu.be/SqfdHZm4_b4?si=1PuJo2xlhSanCUcJ"
          target="_blank"
          rel="noreferrer"
        >
          <p className={tipTextStyle}>
            Watch the Youtube tutorial for more tips
          </p>
        </a>
      </div>

      <p className={textStyle}>
        Once your style is trained, you can draw with it in the app.
      </p>

      <RoundedButton
        className={css`
          width: 367px;
          height: 80px;
          font-size: 24px;
          border-radius: 100px;
          position: absolute;
          bottom: 28px;
        `}
        onClick={() => onNext(2)}
      >
        Get started
      </RoundedButton>
    </Flex>
  );
};

const ExampleStyle = ({
  title,
  images,
}: {
  title: string;
  images: string[];
}) => {
  return (
    <div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          gap: 10px;
          margin-bottom: 16px;
        `}
      >
        {images.map((image, i) => (
          <img key={i} src={image} alt={`example ${i + 1}`} />
        ))}
      </div>
      <p
        className={css`
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
          line-height: 27px;
          text-align: center;
        `}
      >
        “{title}”
      </p>
    </div>
  );
};

const textStyle = css`
  font-size: 22px;
  font-weight: 400;
  line-height: 32.4px;
  text-align: center;
  color: #ffffff;
`;

const tipTextStyle = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 24.3px;
  text-align: center;
  color: #63d0ff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
`;

const imageBoxStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 400px;
  border-radius: 40px;
  border: 1px solid #909090;
  background: #282828;
  margin: 14px 0;
  padding: 24px 0px;
`;
