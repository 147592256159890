import { css } from "@emotion/css";
import { Flex } from "./Flex";
import { useAuth } from "@contexts/AuthContext";

export const FOOTER_HEIGHT = 136;

export const Footer = () => {
  const { logout, isAuthenticated, loading } = useAuth();

  if (loading || !isAuthenticated) {
    return null;
  }

  return (
    <footer className={wrapperStyle}>
      <Flex justify="space-between" className={textWrapperStyle}>
        <p className={wandTextStyle}>© 2024 Wand Technologies</p>
        <button className={logoutStyle} onClick={() => logout()}>
          Log out
        </button>
      </Flex>
    </footer>
  );
};

const wrapperStyle = css`
  display: flex;
  justify-content: center;
`;

const textWrapperStyle = css`
  max-width: 960px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
`;

const wandTextStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #aaaaaa;
`;

const logoutStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #aaaaaa;
`;
