import styled from "@emotion/styled";

export const Flex = styled.div<{
  justify?:
    | "flex-start"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  align?: "flex-start" | "center" | "flex-end" | "baseline" | "stretch";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
}>`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "stretch" }) => align};

  ${({ className }) => className && `&.${className}`};
`;
