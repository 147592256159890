import { http } from ".";
import { TrainingStatus } from "./models";

interface FinetuningConcept {
  id: number;
  name: string;
  token?: string;
}

export interface Style {
  id: string;
  creator: string;
  tree: string;
  concepts: Array<FinetuningConcept>;
  status?: TrainingStatus;
  save_path?: string;
  name: string;
  created?: string;
  created_at: string;
  updated_at: string;
  thumbnail?: string;
  public: boolean;
  hyperparameters: { [key: string]: string | undefined };
  training_steps: number;
  total_training_steps: number;
  modal_cid: string;
  foundation_model: "wuerstchen" | "sdxl" | "sd";
  parent?: Style;
}

export async function fetchStyles() {
  return http.get<Style[]>("/styles/");
}
