import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "@images/wand-header-logo.svg";
import DeactivatedLibraryIcon from "@images/deactivated-library-icon.svg";
import LibraryIcon from "@images/library-icon.svg";
import DeactivatedStyleIcon from "@images/deactivated-style-icon.svg";
import StyleIcon from "@images/style-icon.svg";
import { css } from "@emotion/css";
import { useAuth } from "@contexts/AuthContext";
import { NewStyleModal } from "@containers/NewStyleModal";
import { Flex } from "./Flex";
import { useModalWithUrl } from "@containers/NewStyleModal/hooks/useModalWithUrl";

export const HEADER_Z_INDEX = 999;
export const HEADER_HEIGHT = 100;

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  const {
    isOpen: modalOpen,
    currentStep,
    openModal,
    closeModal,
    nextStep,
    prevStep,
  } = useModalWithUrl();

  const isStyleLibraryActivated = location.pathname.includes("/style-library");
  const isMyLibraryActivated = location.pathname.includes("/my-library");

  const renderDefaultMenu = () => (
    <Flex align="center">
      <Link to="/careers">
        <button className={menuItemStyle}>Careers</button>
      </Link>
      <a href="https://twitter.com/wand_app" target="_blank" rel="noreferrer">
        <button className={menuItemStyle}>Twitter</button>
      </a>
      <a href="https://discord.gg/XA95uttmsV" target="_blank" rel="noreferrer">
        <button className={menuItemStyle}>Discord</button>
      </a>
      <a
        href="https://apps.apple.com/us/app/wand-ai-canvas-for-artists/id1635194640"
        target="_blank"
        rel="noreferrer"
      >
        <button className={menuCTAStyle}>Get Wand</button>
      </a>
    </Flex>
  );

  const renderAuthenticatedMenu = () => (
    <Flex align="center">
      <button
        className={iconMenuItemStyle}
        onClick={() => {
          navigate("/my-library");
        }}
      >
        <img
          src={isMyLibraryActivated ? LibraryIcon : DeactivatedLibraryIcon}
          alt="deactivated-library-icon"
          className={iconStyle(isMyLibraryActivated)}
        />
        My Library
      </button>
      <button
        className={iconMenuItemStyle}
        onClick={() => {
          navigate("/style-library");
        }}
      >
        <img
          src={isStyleLibraryActivated ? StyleIcon : DeactivatedStyleIcon}
          alt="deactivated-library-icon"
          className={iconStyle(isStyleLibraryActivated)}
        />
        Styles
      </button>
      <button className={menuCTAStyle} onClick={openModal}>
        New Style
      </button>
    </Flex>
  );

  return (
    <>
      <Flex justify="center" className={wrapperStyle}>
        <div className={innerWrapperStyle}>
          <Link to="/">
            <img className={logoStyle} src={logo} loading="lazy" alt="Logo" />
          </Link>
          {loading
            ? null
            : isAuthenticated
            ? renderAuthenticatedMenu()
            : renderDefaultMenu()}
        </div>
      </Flex>
      <NewStyleModal
        open={modalOpen}
        step={currentStep}
        onClose={closeModal}
        onNext={nextStep}
        onBack={prevStep}
      />
    </>
  );
};

const wrapperStyle = css`
  position: sticky;
  top: 0;
  z-index: ${HEADER_Z_INDEX};
  background-color: #000000;
`;

const innerWrapperStyle = css`
  box-sizing: border-box;
  max-width: 960px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 0px 16px 16px;
  border-bottom: 1px solid #ffffff33;
`;

const logoStyle = css`
  width: 120px;
  height: 40px;
`;

const menuItemStyle = css`
  color: #adadad;
  font-size: 18px;
  font-weight: 500;
  margin-right: 14px;
`;

const iconMenuItemStyle = css`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
  padding-left: 12px;
  box-sizing: border-box;
`;

const iconStyle = (isActive: boolean) => css`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
  transform: ${isActive ? "scale(1.1)" : "scale(1)"};
`;

const menuCTAStyle = css`
  box-sizing: border-box;
  width: 120px;
  height: 40px;
  margin-left: 14px;
  background: #0f7dff;
  border-radius: 24px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-out, opacity 0.3s ease-out;

  &:hover {
    background-color: #449bff;
    opacity: 1;
  }
`;

export default Header;
