import { ReactNode } from "react";
import { css } from "@emotion/css";
import LoadingIcon from "@images/fade-stagger-circles.svg";
import { WAND_PRIMARY_COLOR } from "@constants/colors";

interface Props {
  visible: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  children: ReactNode;
}

export const DownloadButton = ({
  visible,
  disabled = false,
  loading = false,
  onClick,
  children,
}: Props) => {
  const _disabled = disabled || loading;

  return (
    <button
      className={buttonStyle(visible, _disabled)}
      onClick={onClick}
      disabled={_disabled}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};

const Loader = () => {
  return (
    <img
      src={LoadingIcon}
      alt="loading"
      className={css`
        width: 40px;
        height: 40px;
      `}
    />
  );
};

const buttonStyle = (visible: boolean, disabled: boolean) => css`
  position: fixed;
  z-index: 999;
  bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 360px;
  border-radius: 100px;
  background-color: #0f7dff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 6px 0px #00000059;
  cursor: ${disabled ? "not-allowed" : "pointer"};
  transition: opacity 0.3s ease-out, transform 0.3s ease-out,
    visibility 0.3s ease-out, background-color 0.3s ease-out;
  opacity: ${visible && disabled ? 0.45 : !disabled ? 1 : 0};
  transform: ${visible ? "translateY(0)" : "translateY(-10px)"};
  visibility: ${visible ? "visible" : "hidden"};

  &:hover {
    background-color: ${disabled ? WAND_PRIMARY_COLOR : "#449bff"};
    opacity: ${disabled ? 0.45 : 1};
  }
`;
