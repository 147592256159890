import { http } from ".";
import { TrainingStatus } from "./models";

interface ProgressResponse {
  status: TrainingStatus;
  percentage_complete?: number;
  time_till_completion_estimate_label: string;
  time_till_completion_estimate_amount?: number;
}

export async function fetchFinetuningModelProgress(modelId: string) {
  return http.get<ProgressResponse>(`/finetuning-models/${modelId}/progress/`);
}
