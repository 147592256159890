import { http } from ".";

interface CurrentSubscriptionResponse {
  id: string;
  product: {
    id: string;
    revenue_cat_product_id: string;
    display_name: string;
    style_credits: number;
    image_credits: number;
    tier: "FREE" | "BASIC" | "PRO";
    renewable_type: "MONTHLY" | "NONRENEWABLE";
  };
  style_credits: number;
  image_credits: number;
  start_date: Date;
  end_date?: Date;
  transaction_id?: string;
  original_transaction_id?: string;
  payment_source?: "APPLE" | "STRIPE";
  cancelled: boolean;
  period_type: "TRIAL";
  user: number;
}

export async function fetchCurrentSubscription() {
  return http.get<CurrentSubscriptionResponse>(`/subscriptions/current/`);
}
