import { useState } from "react";
import axios, { AxiosError } from "axios";

interface WandError {
  code: string;
  detail: string;
}

const DEFAULT_ERROR_MESSAGE =
  "An unexpected error occurred. Please try again later.";

export function useErrorHandling() {
  const [error, setError] = useState<string | null>(null);

  const handleError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<WandError>;
      setError(axiosError.response?.data?.detail || DEFAULT_ERROR_MESSAGE);
    } else {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const clearError = () => {
    setError(null);
  };

  return [error, handleError, clearError, setError] as const;
}
