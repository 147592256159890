import { css } from "@emotion/css";

interface Props {
  size: number;
  vertical?: boolean;
  horizental?: boolean;
}

export const Spacing = ({ size, horizental }: Props) => (
  <div
    className={
      horizental
        ? css`
            height: 100%;
            width: ${size}px;
          `
        : css`
            height: ${size}px;
            width: 100%;
          `
    }
  />
);
