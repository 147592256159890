import { http } from ".";
import { WandImage } from "./models";

interface FinetuningConceptImage {
  id: number;
  concept: number;
  image: WandImage;
}

export async function requestFinetuningImages(image: File, conceptId: number) {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("concept", conceptId.toString());

  return http.post<FinetuningConceptImage>("/finetuning-images/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 360000,
  });
}
