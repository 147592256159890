import { useQuery } from "@tanstack/react-query";
import { fetchFinetuningModelProgress } from "@remotes/fetchFinetuningModelProgress";

export function useFinetuningModelProgress(
  modelId?: string | null,
  enabled = true
) {
  return useQuery({
    queryKey: ["useFinetuningModelProgress", modelId],
    queryFn: () => fetchFinetuningModelProgress(modelId!),
    enabled: !!modelId && enabled,
    refetchInterval: 3000,
  });
}
