import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import SignUp from "containers/SignUp";
import EmailAddress from "containers/SignUp/EmailAddress";
import CreateAccount from "containers/SignUp/CreateAccount";
import Login from "containers/Login";
import VerifyEmail from "containers/SignUp/VerifyEmail";
import StyleLibrary from "containers/StyleLibrary";
import MyLibrary from "containers/MyLibrary";
import Header, { HEADER_HEIGHT } from "components/Header";
import { Footer, FOOTER_HEIGHT } from "components/Footer";
import PrivateRoute from "components/PrivateRoute";
import { AuthProvider } from "contexts/AuthContext";

const queryClient = new QueryClient();

const App: React.FC = () => {
  // useEffect(() => {
  //   // This sets the background color of the body
  //   document.body.style.backgroundColor = "#262626"; //
  //   // Optional: Reset the background color when the component unmounts
  //   return () => {
  //     document.body.style.backgroundColor = ""; // Resets to default background color
  //   };
  // }, []);

  return (
    <RecoilRoot>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Header />
            <PageWrapper>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route
                  path="/sign-up/email-address"
                  element={<EmailAddress />}
                />
                <Route path="/sign-up/verify-email" element={<VerifyEmail />} />
                <Route
                  path="/sign-up/create-account"
                  element={<CreateAccount />}
                />
                <Route path="/login" element={<Login />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/style-library" element={<StyleLibrary />} />
                  <Route path="/my-library" element={<MyLibrary />} />
                </Route>
              </Routes>
            </PageWrapper>
            <Footer />
          </AuthProvider>
        </QueryClientProvider>
      </Router>
    </RecoilRoot>
  );
};

const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
`;

export default App;
