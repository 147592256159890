import { http } from ".";
import { FinetuningModel } from "./models";

export async function requestFinetuningModelTraining(
  modelId: string,
  training_steps = 1000
) {
  return http.post<FinetuningModel>(`/finetuning-models/${modelId}/train/`, {
    training_steps,
  });
}
