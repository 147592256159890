import { useQuery } from "@tanstack/react-query";
import { fetchStyles } from "@remotes/fetchStyles";

export function useStyles() {
  return useQuery({
    queryKey: [useStyles.key],
    queryFn: fetchStyles,
  });
}

useStyles.key = "useStyles";
