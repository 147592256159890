import { css } from "@emotion/css";
import { GenerationImage } from "@remotes/fetchGenerationImagesPages";
import { ImageCard } from "./ImageCard";
import { Dispatch, SetStateAction } from "react";

interface Props {
  date: string;
  images: GenerationImage[];
  selectMode: boolean;
  setSelectMode: Dispatch<SetStateAction<boolean>>;
  selectedImages: Set<string>;
  setSelectedImages: Dispatch<SetStateAction<Set<string>>>;
}

export const ImageGroup = ({
  date,
  images,
  selectMode,
  setSelectMode,
  selectedImages,
  setSelectedImages,
}: Props) => {
  const toggleSelect = (id: string) => {
    setSelectedImages((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    setSelectedImages((prev) => {
      const newSelected = new Set(prev);
      images.forEach((image) => newSelected.add(image.id));
      return newSelected;
    });
  };

  const handleClearSelection = () => {
    setSelectMode(false);
    setSelectedImages(new Set());
  };

  return (
    <div className={wrapperStyle}>
      <div className={headerStyle}>
        <div className={dateStyle}>{formatDate(date)}</div>
        <div className={buttonContainerStyle(!selectMode)}>
          <button className={buttonStyle} onClick={() => setSelectMode(true)}>
            Select
          </button>
        </div>
        <div className={buttonContainerStyle(selectMode)}>
          <button className={buttonStyle} onClick={handleSelectAll}>
            Select all
          </button>
          <button className={clearButtonStyle} onClick={handleClearSelection}>
            Clear selection
          </button>
        </div>
      </div>

      <div className={cardWrapperStyle}>
        {images.map((v) => (
          <ImageCard
            key={v.id}
            {...v}
            selectMode={selectMode}
            selected={selectedImages.has(v.id)}
            toggleSelect={toggleSelect}
          />
        ))}
      </div>
    </div>
  );
};

const wrapperStyle = css`
  margin-bottom: 80px;
`;

const headerStyle = css`
  padding: 0 16px 16px;
  position: relative;
`;

const buttonContainerStyle = (visible: boolean) => css`
  position: absolute;
  right: 16px;
  bottom: 14px;
  display: flex;
  gap: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: ${visible ? 1 : 0};
  transform: ${visible ? "translateY(0)" : "translateY(-10px)"};
  visibility: ${visible ? "visible" : "hidden"};
`;

const formatDate = (dateString: string) => {
  const date = new Date(`${dateString}T00:00:00`);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const isYesterday =
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear();

  if (isToday) return "Today";
  if (isYesterday) return "Yesterday";

  return dateString;
};

const cardWrapperStyle = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
`;

const dateStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #aaaaaa;
`;

const buttonStyle = css`
  display: block;
  background-color: #000000;
  border: 1px solid #ffffff33;
  border-radius: 100px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #aaaaaa;
`;

const clearButtonStyle = css`
  ${buttonStyle};
  background-color: #333333;
  border-color: rgba(255, 255, 255, 0.2);
`;
