import { useQuery } from "@tanstack/react-query";
import { fetchFinetuningModel } from "@remotes/fetchFinetuningModel";

export function useFinetuningModel(modelId?: string | null) {
  return useQuery({
    queryKey: ["useFinetuningModel", modelId],
    queryFn: () => fetchFinetuningModel(modelId!),
    enabled: !!modelId,
  });
}
