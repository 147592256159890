import { http } from ".";
import { WandImage } from "./models";

export interface GenerationImage {
  id: string;
  model_id: string;
  project_id: string;
  created_at: string;
  request: string; // eg. ec5ea72b-8766-41ac-874e-23487f8efaf6
  image: WandImage;
}

interface PaginatedGenerationImageList {
  next?: string;
  previous?: string;
  results: GenerationImage[];
}

export async function fetchGenerationImagesPages(cursor = "") {
  const url = cursor
    ? `/generation-images-pages/?cursor=${cursor}`
    : "/generation-images-pages/";
  return http.get<PaginatedGenerationImageList>(url);
}
